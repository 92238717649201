<template>
    <div class="row row-container" :class="$attrs.class">
        <div class="col-12 row-container">
            <div class="row">
                <div class="col-12">
                    <ODataLookup :data-object="dsDocDistributionsLkp" :bind="addDistributionGroup" noClear>
                        <template #target="{ target }">
                            <h6 :ref="target" class="d-inline text-primary" style="cursor:pointer"><i class="bi bi-plus-lg"></i> {{$t('Distribution Groups')}}</h6>
                        </template>
                        <OColumn name="ID" width="80"/>
                        <OColumn name="Name" width="300"/>
                    </ODataLookup>
                </div>
            </div>
            <o-data-grid :data-object="dsDocDistributions" hide-grid-menu hideNewRecords style="max-height:400px">
                <o-column field="Distribution" width="400" sortable :headerName="$t('Distribution')">
                    <template #default="{row}">
                         <span style="cursor:pointer;" @click="showDetailsModal">{{ row.Distribution }}</span>
                    </template>
                </o-column>
                <o-column field="PersonsCount" width="100" sortable :headerName="$t('Persons')">
                    <template #default="{row}">
                         <span style="cursor:pointer;" @click="showDetailsModal">{{ row.PersonsCount }}</span>
                    </template>
                </o-column>
                <o-column field="OrgUnitsCount" width="100" sortable :headerName="$t('Org Units')">
                    <template #default="{row}">
                         <span style="cursor:pointer;" @click="showDetailsModal">{{ row.OrgUnitsCount }}</span>
                    </template>
                </o-column>
                <o-column field="ManuallyAdded" width="120" hide :headerName="$t('Manually Added')"></o-column>
            </o-data-grid>
        </div>
    </div>

    <OModal ref="groupDetailsModalRef">
        <div class="modal-dialog modal-xl" role="document" >
            <div class="modal-content">
                <h5 class="modal-header">
                    <span>{{ dsDocDistributions.current.Distribution }}</span>
                    <a :href="`/arena-distribution-setup/arena-distribution-setup-details?ID=${dsDocDistributions.current.ID}`" target="_blank" :title="$t('Open setup')"><i class="bi bi-gear-fill mt-1 ms-2"></i></a> <!--NT app to be implemented-->
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </h5>
                <div class="modal-body row">
                    <div class="col-8 pr-0">
                        <h6>{{$t('Persons')}}</h6>
                        <o-data-grid :data-object="dsDistPersons" hide-grid-menu hideMultiselectColumn hideActionColumn hideSystemColumn style="max-height:300px;">
                            <o-column field="Person" width="200" sortable :headerName="$t('Person')">
                                <template #default="{row}">
                                    <span v-person-hover="row.Person_ID">{{row.Person}}</span>
                                </template>
                            </o-column>
                            <o-column field="ForReview" width="100" sortable :headerName="$t('For Review')"></o-column>
                            <o-column field="ForApproval" width="100" sortable :headerName="$t('For Approval')"></o-column>
                            <o-column field="Check" width="60" sortable :headerName="$t('Check')"></o-column>
                            <o-column field="ForInfo" width="70" sortable :headerName="$t('For Info')"></o-column>
                            <o-column field="Subscribe" width="82" sortable :headerName="$t('Subscribe')"></o-column>
                            <o-column field="AllowEdit" width="85" sortable :headerName="$t('Allow Edit')"></o-column>
                        </o-data-grid>
                    </div>
                    <div class="col-4">
                        <h6>{{$t('Org Units')}}</h6>
                        <o-data-grid :data-object="dsDistOrgUnits" hide-grid-menu hideMultiselectColumn hideActionColumn hideSystemColumn style="max-height:300px;">
                            <o-column field="OrgUnit" width="300" sortable></o-column>
                        </o-data-grid>
                    </div>
                </div>
                <div class="modal-footer border-top-0">
                    <button class="btn btn-outline-primary" data-bs-dismiss="modal">{{$t('Close')}}</button>
                </div>
            </div>
        </div>
    </OModal>

</template>

<script setup>
    import { ref,watch } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts'
    import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts';
    import OModal from 'o365.vue.components.Modal.vue';
    import vPersonHover from 'o365.vue.directive.personHover.ts';

    const props = defineProps({
        dataObject: Object
    })
    const emits = defineEmits(['insertDelete']);

    const groupDetailsModalRef = ref(null);

    const dsDocDistributionsDefinition = {
        id: 'dsDocDistributions',
        viewName: 'aviw_ECM_DocumentsDistributions',
        appId: props.dataObject.appId,
        loadRecents: true,
        distinctRows: true,
        uniqueTable:"atbv_ECM_DocumentsDistributions",
        allowDelete: true,
        allowInsert:true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Document_ID", type: "number" },
                {name: "Distribution_ID", type: "number"},
                {name: "Distribution", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "PersonsCount", type: "number"},
                {name: "OrgUnitsCount", type: "number"},
                {name: "ManuallyAdded", type: "bit"}
            ]
    }, dsDocDistributionsLkpDefinition = {
        id: 'dsDocDistLkp',
        viewName: 'aviw_ECM_DistributionsLookup',
        appId: props.dataObject.appId,
        loadRecents: true,
        distinctRows: true,
        selectFirstRowOnLoad: true,
        disableAutoLoad: true,
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"}
            ]
    };

    const dsDocDistributions = getOrCreateDataObject(dsDocDistributionsDefinition);
    const dsDocDistributionsLkp = getOrCreateDataObject(dsDocDistributionsLkpDefinition);
    dsDocDistributionsLkp.recordSource.whereClause = `'${props.dataObject.current.OrgUnitIdPath}' like IdPath AND Closed IS NULL`;

    const dsDistPersonsDef= {
        id: 'dsDistPersons',
        viewName: 'aviw_ECM_DistributionsPersons',
        appId: props.dataObject.appId,
        disableAutoLoad: true,
        selectFirstRowOnLoad: true,
        masterDataObject_ID: "dsDocDistributions",
        masterDetailDefinition: [{
            detailField: "Distribution_ID",
            masterField: "Distribution_ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "Person_ID", type: "number"},
                {name: "Person", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "ForApproval", type: "bit" },
                {name: "Check", type: "bit"},
                {name: "ForReview", type: "bit"},
                {name: "ForInfo", type: "bit"},
                {name: "AllowEdit", type: "bit"},
                {name: "Subscribe", type: "bit"}
            ]
    },
    dsDistOrgUnitsDef= {
        id: 'dsDistOrgUnits',
        viewName: 'aviw_ECM_DistributionsOrgUnits',
        appId: props.dataObject.appId,
        disableAutoLoad: true,
        selectFirstRowOnLoad: true,
        masterDataObject_ID: "dsDocDistributions",
        masterDetailDefinition: [{
            detailField: "Distribution_ID",
            masterField: "Distribution_ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "OrgUnit", type: "string", sortOrder: 1, sortDirection: "asc"}
            ]
    }

    const dsDistPersons = getOrCreateDataObject(dsDistPersonsDef);
    const dsDistOrgUnits = getOrCreateDataObject(dsDistOrgUnitsDef);

    function addDistributionGroup(row){
        dsDocDistributions.createNew({Distribution_ID: row.ID }, false);
        dsDocDistributions.save().then(res => {
            emits("insertDelete");
            dsDocDistributions.load(); // loading should not be necessary, but seemingly has to be done now
        }).catch(error =>{
            dsDocDistributions.cancelChanges();
        });
    }

    useDataObjectEventListener(dsDocDistributions, 'AfterDelete', (options, row) => {
        emits("insertDelete");
    });

    function showDetailsModal(){
        groupDetailsModalRef.value.show();
        dsDistPersons.load();
        dsDistOrgUnits.load();
    }

</script>